import React from "react";
import "./Logo.css";

const Logo = ({ width }) => {
  return (
    <img
      src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/Janice-logo.png"
      alt="Janice Carissa Logo"
      style={{ width: width, height: "auto", zIndex: "1" }}
    ></img>
  );
};

export default Logo;
