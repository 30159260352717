import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Footer";
import ImageGallery from "./ImageGallery";
import "./Photos.css";

function Photos() {
  return (
    <div className="photos">
      <header>
        <Navbar />
      </header>
      <main>
        <div className="image-gallery-container">
          <ImageGallery />
        </div>
      </main>
    </div>
  );
}

export default Photos;
