import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import Navbar from "../navbar/Navbar";
import Logo from "../logo/Logo";
import "../logo/Logo.css";
import "./Contact.css";

const Contact = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <div className="contact-page">
      <header>
        <Navbar />
      </header>
      <main>
        <Container>
          <Row>
            <div className={!isMobile ? "logo-container-hero" : "logo-container-mobile"}>
              <Logo width={!isMobile ? "10%" : "20%"} />
            </div>
          </Row>
          <Row>
            <Col xs={12} md={6} className="text-center">
              <div className="contact-info-container">
                <a
                  href="https://www.opus3artists.com/artists/janice-carissa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/opus3image.png"
                    className="management-photo"
                  />
                </a>
                <div className="contact-info mt-4">
                  <h2>General Management</h2>
                  <p>Sarah Gordon</p>
                  <p>
                    Email:{" "}
                    <a href="mailto:sgordon@opus3artists.com">
                      sgordon@opus3artists.com
                    </a>
                  </p>
                  <p>Phone: (212) 584-7529</p>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="text-center mb-5">
              <div className={!isMobile ? "img-container" : "img-container-mobile"}>
                <Image
                  src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_8994.JPG"
                  alt="Profile Picture"
                  fluid
                />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

export default Contact;
