import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import DateComponent from "../event/DateComponent";
import "./EventModalBody.css";
import Ticket from "../ticket/Ticket";
import { isMobile } from "react-device-detect";
import { parseDate } from "../event/DateComponent";

const EventModalBody = ({ event }) => {
  const renderParticipants = () => {
    if (event.participants) {
      const participant_array = event.participants.split("\n");
      return participant_array.map((participant, index) => {
        return <div key={index}>{participant}</div>;
      });
    }
  };

  const renderProgramLong = () => {
    if (event.program_long) {
      const program_array = event.program_long.split("\n");
      return program_array.map((program, index) => {
        return <div key={index}>{program}</div>;
      });
    }
  };

  const renderMobileDate = (date) => {
    const { month, day, year } = parseDate(date, false);
    return (
      <div className="my-2 event-modal-date-mobile">
        {month} {day}, {year}
      </div>
    );
  };

  return (
    <div className="event-modal-card">
      <Container>
        <Row>
          <Col md={2}>
            {!isMobile && (
              <div className="event-modal-date">
                <DateComponent date={event.date} size={"lg"} />
              </div>
            )}
          </Col>
          <Col md={8}>
            <div style={{ textAlign: "left", paddingLeft: "5%" }}>
              <div className="event-modal-name mt-2">{event.name}</div>
              <p className="event-modal-location mb-0 mt-0">{event.location}</p>
              {isMobile && <div>{renderMobileDate(event.date)}</div>}
              <div className="participants">{renderParticipants()}</div>
              <div className="program-long mt-4 mb-2">
                {renderProgramLong()}
              </div>
            </div>
          </Col>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <Ticket event={event} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EventModalBody;
