import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import "./Recording.css";

function Recording() {

  const { videoUrl } = useParams();

  return (
    <div className="recording">
      <header>
        <Navbar />
      </header>
      <div className="janice-logo">
          <a href="/">
            <img
              src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/Janice-logo.png"
              alt="Janice logo"
              style={{ width: "7rem", height: "auto" }}
            />
          </a>
        </div>
      <main>
        <div className="recording-container">
          {videoUrl ? (
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${videoUrl}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>
          ) : (
            <p>No video available.</p>
          )}
        </div>
      </main>
    </div>
  );
}

export default Recording;