import React, { useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import DateComponent from "./DateComponent";
import "./Event.css";
import EventModalBody from "../event_modal/EventModalBody";
import Ticket from "../ticket/Ticket";

const isMobile = window.innerWidth <= 768;

const Event = ({ event }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const eventModal = (
    <Modal size="xl" show={isModalOpen} onHide={closeModal} centered>
      <EventModalBody event={event} />
    </Modal>
  );

  const mobileEvent = (
    <div className="event-card" onClick={openModal}>
      <Container>
        <Row>
          <Col xs={2} style={{ padding: "0.25rem" }}>
            <div className="row-xs-6">
              <DateComponent date={event.date} size={"sm"} />
            </div>
          </Col>
          <Col xs={10}>
            <div style={{ textAlign: "left" }}>
              <div className="event-name">{event.name}</div>
              <p className="location mb-0 mt-0">{event.location}</p>
              <p className="program mb-0">
                {event.program !== "" ? event.program : "Program TBA"}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      {eventModal}
    </div>
  );

  const desktopEvent = (
    <div className="event-card" onClick={openModal}>
      <Container>
        <Row>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <DateComponent date={event.date} size={"md"} />
          </Col>
          <Col md={8}>
            <div style={{ textAlign: "left", paddingLeft: "5%" }}>
              <div className="event-name">{event.name}</div>
              <p className="location mb-0 mt-0">{event.location}</p>
              <p className="program mb-0">
                {event.program !== "" ? event.program : "Works TBD"}
              </p>
            </div>
          </Col>
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <Ticket event={event} />
          </Col>
        </Row>
      </Container>
      {eventModal}
    </div>
  );

  return isMobile ? mobileEvent : desktopEvent;
};

export default Event;
