import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {

  const logoStyle = {
    textAlign: 'center'
  };

  return (
    <footer className="footer bg-light text-center mt-auto py-3">
      <Container>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="logo" style={logoStyle}>
              <img src="https://images.squarespace-cdn.com/content/v1/60a7c5ced20de561852e6435/9d159101-f710-48b8-ad88-a313a22be6e5/Opus3Artists_BLACKREDlogo.png?format=500w"
                alt="Footer Image"
                className="img-fluid" />
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
