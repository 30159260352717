import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navbar.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Navbar() {
  const [showLinks, setShowLinks] = useState(false);

  const menuRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowLinks(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="hamburger-menu">
      <button onClick={() => setShowLinks(!showLinks)}>
        <FontAwesomeIcon icon={faBars} size="sm" style={{ color: "#ffffff" }} />
      </button>

      {showLinks && (
        <div className="links">
          <button className="close-btn" onClick={() => setShowLinks(false)}>
            ✕
          </button>
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/schedule">Schedule</a>
          <a href="/photos">Photos</a>
          <a href="/contact">Contact</a>
        </div>
      )}
    </div>
  );
}

export default Navbar;
