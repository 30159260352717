import React from "react";
import "./DateComponent.css";
import { isMobile } from "react-device-detect";

export const parseDate = (dateString, addNewLines) => {
  const date = new Date(dateString);
  const month = date.toLocaleString("default", { month: "short" });
  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;
  let year = date.getFullYear().toString();

  if (addNewLines) {
    year = year.split("").join("\n");
  }
  return { month, day, year };
};

const DateComponent = ({ date, size }) => {
  const dayStyle = () => {
    let style = { fontFamily: "Qlassy", position: "absolute" };
    if (size === "sm") {
      style = {
        ...style,
        fontSize: "2.5rem",
        lineHeight: "2.5rem",
        top: "1rem",
        left: "0.25rem",
      };
    } else if (size === "md") {
      style = {
        ...style,
        fontSize: "3.8rem",
        lineHeight: "3.5rem",
        top: "1.5rem",
        left: "0.4rem",
      };
    } else if (size === "lg") {
      style = {
        ...style,
        fontSize: "5.5rem",
        lineHeight: "4.5rem",
        top: "2.5rem",
        left: "0rem",
      };
    }
    return style;
  };

  const yearStyle = () => {
    let style = { fontFamily: "Evagrande", position: "absolute" };
    if (size === "sm") {
      style = {
        ...style,
        fontSize: "1rem",
        lineHeight: "0.8rem",
        top: "0.6rem",
        left: "2.8rem",
        width: "1rem",
      };
    } else if (size === "md") {
      style = {
        ...style,
        fontSize: "1.5rem",
        lineHeight: "1.2rem",
        top: "0.8rem",
        left: "4.5rem",
        width: "1.5rem",
      };
    } else if (size === "lg") {
      style = {
        ...style,
        fontSize: "1.8rem",
        lineHeight: "1.7rem",
        top: "0.8rem",
        left: "6rem",
        width: "1.8rem",
      };
    }
    return style;
  };

  const monthStyle = () => {
    let style = {
      fontFamily: "Le Jour Serif",
      position: "absolute",
      letterSpacing: "0.1rem",
    };
    if (size === "sm") {
      style = {
        ...style,
        fontSize: "1.2rem",
        lineHeight: "1.5rem",
        height: "1.2rem",
        top: "0rem",
        left: "0rem",
      };
    } else if (size === "md") {
      style = {
        ...style,
        fontSize: "1.8rem",
        lineHeight: "2rem",
        height: "2rem",
        top: "0rem",
        left: "3px",
      };
    } else if (size === "lg") {
      style = {
        ...style,
        fontSize: "2.5rem",
        lineHeight: "2.5rem",
        height: "2.2rem",
        top: "0.25rem",
        left: "0rem",
      };
    }
    return style;
  };

  const parsedDate = parseDate(date, true);

  return (
    <div className="date mt-md-2 ml-md-2 mt-xs-0 ml-xs-0">
      <div className="month" style={monthStyle()}>
        {parsedDate.month.toUpperCase()}
      </div>
      <div className="day mb-0 mt-0" style={dayStyle()}>
        {parsedDate.day}
      </div>
      <div className="year mb-0 mr-0" style={yearStyle()}>
        {parsedDate.year}
      </div>
    </div>
  );
};

export default DateComponent;
