import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Navbar from "../navbar/Navbar";
import Logo from "../logo/Logo";
import "./About.css";
import "../logo/Logo.css";

function About() {
  const isMobile = window.innerWidth <= 768;

  return (
    <div className="about-container">
      <header>
        <Navbar />
      </header>
      <main>
        {isMobile && (
          <div className="logo-container-mobile">
            <Logo width={"20%"} />
          </div>
        )}
        <Container>
          <Row>
            <Col md={6}>
              <div className={isMobile ? "about-img-container-mobile" : "about-img-container"}>
                <Image
                  src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5257.jpg"
                  alt="Profile Picture"
                  fluid
                />
              </div>
            </Col>
            <Col md={6}>
              {!isMobile && (
                <div className="logo-container my-5">
                  <Logo width={"20%"} />
                </div>
              )}
              <div className="about-text my-4">
                <p>
                  A Gilmore Young Artist and winner of Salon de Virtuosi, Janice
                  Carissa has “the multicolored highlights of a mature pianist“
                  (Philly Inquirer) and “strong, sure hands” (Voice of America)
                  that “conveys a vivid story rather than a mere showpiece.”
                  (Chicago Classical Review) She has garnered great acclaims at
                  renowned concert halls, including the Sydney Opera House,
                  Carnegie Hall, United Nations, Kennedy Center, Jay Pritzker
                  Pavilion in Millennium Park, Louis Vuitton Foundation,
                  Saratoga Performing Arts Center.
                </p>
                <p>
                  Following her Philadelphia Orchestra debut at age sixteen,
                  Janice has substituted Andre Watts as soloist with Orpheus
                  Chamber Orchestra and gone on to perform with the Kansas City,
                  Amarillo, Des Moines, John Hopkins, St. Peters by the Sea,
                  Symphony in C, Eastern Wind, Bay Atlantic and Midwest Young
                  Artist symphonies. In 2023, she will be a featured soloist
                  with Sacramento Philharmonic, Promusica Chamber Orchestra,
                  Curtis Symphony Orchestra, and the Tacoma and Battlecreek
                  symphonies.
                </p>
                <p>
                  Janice's passion for chamber music has lead her to
                  performances with Brooklyn Chamber Music Society and Jupiter
                  Chamber Concert Series; collaborations with Vadim Gluzman,
                  Miriam Fried, Paul Neuebauer, Lucy Shelton, Marcy Rosen, David
                  Shifrin, Jennifer Cano, Peter Wiley, among other distinguished
                  musicians; and appearances at Marlboro, North Shore, Ravinia,
                  Caramoor, and Kneisel Hall festivals.
                </p>
                <p>
                  A pupil of Gary Graffman and Robert McDonald, Janice left
                  Indonesia in 2013 and entered the Curtis Institute of Music
                  with full scholarship from Gerry and Marguerite Lenfest. Now
                  graduated with Bachelor’s Degree, she is presently pursuing
                  her Master’s Degree at The Juilliard School with Robert
                  McDonald. When away from the piano, Janice is an avid foodie
                  and loves going on strolls with her camera.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
      {/* <footer>
        <Footer />
      </footer> */}
    </div>
  );
}

export default About;
