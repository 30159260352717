import React, { useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import "./Hero.css";
import Navbar from "../navbar/Navbar";
import Logo from "../logo/Logo";
import "../logo/Logo.css";

function HeroSection() {
  const philadelphiaInquirerQuote =
    'Janice Carissa has been praised for radiating "the multicolored highlights of a mature pianist"';
  const chicagoClassicalReviewQuote =
    'a "fleet-fingered touch that is particularly impressive," and "conveys a vivid story rather than a mere showpiece"';
  const philadelphiaInquirer = "- PHILADELPHIA INQUIRER";
  const chicagoClassicalReview = "- CHICAGO CLASSICAL REVIEW";
  const isMobile = window.innerWidth < 1025;
  const isIpad = window.innerWidth < 1025 && window.innerWidth > 768;

  useEffect(() => {
    // Remove carousel ID from the URL
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  const heroDesktop = (
    <>
      <div className="logo-container-hero">
        <Logo width={"10%"} />
      </div>
      <div className="gradient">
        <div className="container">
          <div className="row">
            <div
              className="col-md-5"
              style={{ marginLeft: "10%", marginTop: "20%" }}
            >
              <div className="hero-quote">
                <div className="hero-quote-text">
                  {philadelphiaInquirerQuote}
                </div>
                <div className="row">
                  <div className="hero-quote-author col-10 mt-2">
                    {philadelphiaInquirer}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              style={{
                marginLeft: "25%",
                marginTop: "8%",
                textAlign: "right",
              }}
            >
              <div className="hero-quote">
                <div className="hero-quote-text">
                  {chicagoClassicalReviewQuote}
                </div>
                <div className="row">
                  <div className="hero-quote-author col-11 mt-2">
                    {chicagoClassicalReview}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const heroMobile = (
    <>
      <div className="logo-container-mobile">
        <Logo width={"20%"} />
      </div>
      <Carousel
        controls={false}
        slide={true}
        indicators={false}
        interval={5000}
      >
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div
                className="col-xs-4"
                style={{
                  marginTop: isIpad ? "30%" : "40%",
                  textAlign: "left",
                  paddingLeft: "12%",
                  paddingRight: "12%",
                }}
              >
                <div className="hero-quote">
                  <div className="hero-quote-text">
                    {philadelphiaInquirerQuote}
                  </div>
                  <div className="row">
                    <div className="hero-quote-author col-10 mt-2">
                      {philadelphiaInquirer}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div
                className="col-xs-4"
                style={{
                  marginTop: isIpad ? "30%" : "40%",
                  textAlign: "left",
                  paddingLeft: "12%",
                  paddingRight: "12%",
                }}
              >
                <div className="hero-quote">
                  <div className="hero-quote-text">
                    {chicagoClassicalReviewQuote}
                  </div>
                  <div className="row">
                    <div
                      className="hero-quote-author col-10 mt-2"
                      style={{ fontSize: "1.7rem" }}
                    >
                      {chicagoClassicalReview}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );

  return (
    <>
      <div className="hero-container">
        <header>
          <Navbar />
        </header>
        {isMobile ? heroMobile : heroDesktop}
      </div>
    </>
  );
}

export default HeroSection;
