import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import "./Schedule.css";
import Event from "./event/Event";
import Navbar from "../navbar/Navbar";

function Schedule() {
  const [events, setEvents] = useState([]);
  const [performances_text, setPerformancesText] = useState(
    "Upcoming Performances"
  );

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await fetch("https://sheetdb.io/api/v1/lqlz23qwkljjr");
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const cachedEvents = useMemo(() => events, [events]);

  const isPastEvent = (event) => {
    const today = new Date();
    const eventDate = new Date(event.date);
    return eventDate < today;
  };

  function PerformanceDropdown() {
    const upcoming_performances = "Upcoming Performances";
    const past_performances = "Past Performances";

    const handlePerformances = (text) => () => {
      setPerformancesText(text);
    };

    return (
      <div className="d-flex justify-content-center my-5">
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="custom-dropdown-toggle">
            {performances_text}
          </Dropdown.Toggle>
          <Dropdown.Menu className="custom-dropdown-menu">
            <Dropdown.Item onClick={handlePerformances(performances_text === upcoming_performances ? past_performances : upcoming_performances)} className="custom-dropdown-item">
              {performances_text === upcoming_performances ? past_performances : upcoming_performances}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }

  function eventsList(showPastEvents) {
    const filteredEvents = cachedEvents.filter(event => 
        showPastEvents ? isPastEvent(event) : !isPastEvent(event)
    );

    if (showPastEvents) {
        filteredEvents.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    const eventsToRender = filteredEvents.map((event, index) => (
        <Col md={12} key={index}>
            <Event event={event} />
        </Col>
    ));

    return eventsToRender;
}

  return (
    <>
      <header>
        <Navbar />
      </header>
      <div className="schedule pt-4">
        <div className="keyboard">
          <img
            src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/piano-keyboard.png"
            alt="keyboard"
            style={{ height: "100%", opacity: "0.2" }}
          />
        </div>
        <div className="janice-logo">
          <a href="/">
            <img
              src="https://s3.us-east-2.amazonaws.com/janicecarissa.com/Janice-logo.png"
              alt="Janice logo"
              style={{ width: "7rem", height: "auto" }}
            />
          </a>
        </div>
        <div className="schedule-header mb-4">
          <h1>SCHEDULE</h1>
        </div>
        <PerformanceDropdown />
        <div className="schedule-body">
          <Container>
            <Row>
              <div class="d-flex justify-content-center">
                <Col md={8}>
                  <Row>
                    {eventsList(performances_text === "Past Performances")}
                  </Row>
                </Col>
              </div>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Schedule;
