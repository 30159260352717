import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import "./ImageGallery.css";

const photos = [
  {
    src: "https://images.squarespace-cdn.com/content/v1/60a7c5ced20de561852e6435/c0201bdf-82af-4b64-ad35-ea9b1f25e46a/IMG_5184-Edit.jpg",
    width: 3,
    height: 4.5,
  },
  {
    src: "https://images.squarespace-cdn.com/content/v1/60a7c5ced20de561852e6435/6d66039e-788d-41f5-a535-746f8df85780/IMG_3909-Edit.jpg",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5257.JPG",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_5562.JPG",
    width: 3,
    height: 4.5,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_8992.JPG",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_8994.JPG",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_8998.jpg",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_9001.JPG",
    width: 4.5,
    height: 3,
  },
  {
    src: "https://s3.us-east-2.amazonaws.com/janicecarissa.com/IMG_9003.JPG",
    width: 4.5,
    height: 3,
  },
];

function ImageGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const isMobile = window.innerWidth <= 1024;

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <div>
      {isMobile ? (
        <div>
          <Gallery photos={photos} onClick={openLightbox} />
        </div>
      ) : (
        <div className="horizontal-gallery-container">
          <div className="horizontal-gallery-row">
            {photos
              .slice(0, Math.ceil(photos.length / 2))
              .map((photo, index) => (
                <img
                  key={index}
                  src={photo.src}
                  alt={`Gallery Image ${index}`}
                  className="horizontal-gallery-image"
                  onClick={(event) => openLightbox(event, { photo, index })}
                />
              ))}
          </div>

          <div className="horizontal-gallery-row">
            {photos.slice(Math.ceil(photos.length / 2)).map((photo, index) => (
              <img
                key={index + Math.ceil(photos.length / 2)} // Adjusting the index
                src={photo.src}
                alt={`Gallery Image ${index}`}
                className="horizontal-gallery-image"
                onClick={(event) =>
                  openLightbox(event, {
                    photo,
                    index: index + Math.ceil(photos.length / 2),
                  })
                } // Adjusting the index
              />
            ))}
          </div>
        </div>
      )}

      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
}

export default ImageGallery;
