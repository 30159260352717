import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Ticket = ({ event }) => {
  return (
    event.website && (
      <div className="ticket">
        <a href={event.website} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon
            icon="fa-solid fa-ticket"
            size="2xl"
            style={{ color: "#263550" }}
          />
        </a>
        <div className="ticket-text">
          <a href={event.website} target="_blank" rel="noopener noreferrer">
            Tickets
          </a>
        </div>
      </div>
    )
  );
};

export default Ticket;
